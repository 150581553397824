import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const Websites = () => {
  return (
    <Layout>
      <Head title="Websites" />
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://benjifriedman.github.io/markdownImageLinkHelper/"
        >
          markdownImageLinkHelper
        </a>
        <p>
          Github Pages website tool to format links and images for markdown
          (.md) files.
        </p>
      </li>
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://bingnews.netlify.app/"
        >
          Bing Image News
        </a>
        <p>
          Website that uses the Bing News API to show news stories as image
          thumbnails
        </p>
      </li>
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://mythology.netlify.app/"
        >
          Planet Mythology
        </a>
        <p>
          Website about the planets and their associations in mythologies around
          the world
        </p>
      </li>
      <li>
        <Link to="/iching" style={{ display: "block", marginBottom: "6px" }}>
          I Ching
        </Link>
        <p>Generates hexagrams (reload page for new hexagram)</p>
      </li>
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://benjiartquiz.netlify.app/"
        >
          Art Quiz
        </a>
        <p>Website that asks a couple questions and returns a painting</p>
      </li>
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://benjifriedman.github.io/Document-Merge-and-Scramble/"
        >
          Document Merge and Scramble
        </a>
        <p>
          Accepts two text sources and merges them together in a random word
          order
        </p>
      </li>
      <li>
        <a
          style={{ display: "block", marginBottom: "6px" }}
          href="https://benjisunrise.netlify.app/"
        >
          Sunrise / Sunset
        </a>
        <p>
          Website that uses a weather API to show the sunrise and sunset times
          with city search
        </p>
      </li>
    </Layout>
  );
};

export default Websites;
